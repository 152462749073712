
import React from "react";
import { connect } from "react-redux";
import View from "@modul-connect/shared/components/atoms/view";
import { DateTime } from "luxon";
import { H1, P } from "@modul-connect/shared/components/atoms/text";
import VehiclesList from "../../../vehiclesList";
import "../../../dashboard.css";
import {DayPicker} from 'react-day-picker';
import { DayPickerInput } from "@modul-connect/shared/components/molecules/daySelector/DayPickerInput"
import HideableSidebar from "../../hideableSidebar";
import "react-day-picker/style.css";
import FilterMenu from "./filterMenu";
import { getServiceList } from "../../../../../utils/ServiceList";
import { vehicleMatchesMapFiltering } from "../../../../../utils/vehicleUtils";
import { ServiceType } from "@modul-connect/shared/utils/services";

const OverviewSidebar = ({
  themes,
  organisation,
  vehicles,
  loading,
  showSidebar,
  setShowSidebar,
  date,
  setDate,
  submenuIsOpen,
  setSelectedStartTime,
  setSelectedEndTime,
  startTime,
  endTime,
  filter,
  setFilter,
  subtrees,
  map_settings,
  vehicles_security_system_status
}) => {
  const isMobile = themes.device === 'mobile'

  const handleDayClick = (date, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    setDate(DateTime.fromJSDate(date).toISODate())
  }

  const handleDayChange = (date) => {
    if (!date || date > DateTime.local().toJSDate()) {
      return
    }

    setDate(DateTime.fromJSDate(date).toISODate())
  }

  const selectedDate = DateTime.fromISO(date)
  const services = getServiceList({subtrees})

  // Should only show those vehicles that have routes in the list
  const filtered_vehicles = vehicles.filter(x => vehicleMatchesMapFiltering(
    x, 
    date,
    DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, map_settings?.startClockTime?.hour, map_settings?.startClockTime?.minute),
    DateTime.local(selectedDate.year, selectedDate.month, selectedDate.day, map_settings?.endClockTime?.hour, map_settings?.endClockTime?.minute),
    map_settings))

  let noVehiclesToDisplay = false
  if (loading.fetchVehicleRoutes === null && loading.fetchLastKnownPositions === null && loading.fetchVehicles === null && !filtered_vehicles?.length && !loading.settingMapDate) {
    noVehiclesToDisplay = true
  }

  return (
    <HideableSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} hideOpenCloseButton={submenuIsOpen}>
      {
        date &&
        <View className={'sideMenuContainer'}>
          <View>
            <H1 text={organisation?.name?.toUpperCase()} noPadding />

            {
              !isMobile && <DayPicker
                mode="single"
                styles={{
                  root: {
                    marginBottom: 10
                  },
                }}
                month={DateTime.fromISO(date).toJSDate()}
                selected={DateTime.fromISO(date).toJSDate()}
                onSelect={handleDayClick}
                endMonth={DateTime.local().toJSDate()}
                disabled={[
                  services.hasService(ServiceType.DrivingLogs)
                    ? {
                      after: DateTime.local().toJSDate()
                    }
                    : {
                      before: DateTime.local().toJSDate(),
                      after: DateTime.local().toJSDate()
                    },
                ]}
              />
            }
            {
              isMobile && <View className={"DatePickerContainer"} extend={{ marginTop: 40 }}>
                <DayPickerInput
                  onDayPicked={(dateDT) => handleDayChange(dateDT?.toJSDate())}
                  disableDaysBefore={services.hasService(ServiceType.DrivingLogs) ? undefined : DateTime.local()}
                  disableDaysAfter={DateTime.local()}
                  date={DateTime.fromISO(date)}
                />
              </View>
            }
            
            <FilterMenu 
              themes={themes}
              setSelectedStartTime={setSelectedStartTime}
              setSelectedEndTime={setSelectedEndTime}
              startTime={startTime}
              endTime={endTime}
              filter={filter}
              setFilter={setFilter}
              subtrees={subtrees}/>

            {
              noVehiclesToDisplay ?
              <P>No vehicles available for this day.</P> : null
            }

            {
              loading.fetchVehicleRoutes === 'failed' ?
              <P>Loading vehicle routes failed.</P> : null
            }
          </View>
          <VehiclesList 
            vehicles={filtered_vehicles} 
            vehicles_security_system_status = {vehicles_security_system_status}
            date={date} />
        </View>
      }
    </HideableSidebar>
  )
}

const mapStateToProps = ({
  themes,
  organisation,
  loading,
  vehicles,
  subtrees,
  map_settings,
  //selectedVehicle
}) => ({
  themes,
  organisation,
  loading,
  vehicles,
  subtrees,
  map_settings,
  //selectedVehicle
})

export default connect(mapStateToProps)(OverviewSidebar);